import imageAdvocacy from '../images/categories/advocacy-card.jpg';
import imageEnergy from '../images/categories/energy-card.jpg';
import imageFamily from '../images/categories/family-card.jpg';
import imageFood from '../images/categories/food-card.jpg';
import imageHome from '../images/categories/home-work-card.jpg';
import imageOutside from '../images/categories/outside-card.jpg';
import imageResilience from '../images/categories/resilient-card.jpg';
import imageStuff from '../images/categories/stuff-card.jpg';
import imageTransport from '../images/categories/transport-travel-card.jpg';
import imageGetStarted from '../images/categories/get-started-card.png';
import imageBigImpact from '../images/categories/big-impact-card.jpg';
import imageAllActions from '../images/categories/all-actions-card.jpg';

const imageCategoryMapping = {
    'All Actions': imageAllActions,
    'Advocacy & Choice': imageAdvocacy,
    Energy: imageEnergy,
    'Family & Community': imageFamily,
    Food: imageFood,
    'Home & Work': imageHome,
    Outside: imageOutside,
    Resilience: imageResilience,
    Stuff: imageStuff,
    'Travel & Transport': imageTransport,
    'Get started': imageGetStarted,
    'Big impact': imageBigImpact,
};

const defaultImage = imageAllActions;

export const getCategoryImageURL = category => {
    if (imageCategoryMapping.hasOwnProperty(category)) {
        return imageCategoryMapping[category];
    } else {
        return defaultImage;
    }
};
