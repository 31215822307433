import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { encodeCategoryURL } from '../utils/link-utils.js';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import CategoryList from '../components/Actions/CategoryList';
import ActionsGrid from '../components/Actions/ActionsGrid';

import '../styles/global.scss';
import '../styles/act.scss';

const _convertObject = (accum, [categoryName, actions]) => {
    return {
        ...accum,
        [categoryName]: actions,
    };
};

const selectRandomActions = (actionsDict, numOfRandom) => {
    const featuredActions = [];
    const actionsArray = Object.values(actionsDict);
    while (featuredActions.length < numOfRandom) {
        const randomIndex = Math.floor(Math.random() * actionsArray.length);
        if (!featuredActions.includes(actionsArray[randomIndex])) {
            featuredActions.push(actionsArray[randomIndex]);
        }
    }
    return featuredActions;
};

const ActPageTemplate = ({ pageContext: { collections, categories, actionsDict, navBarType } }) => {
    const [randomActions, setRandomActions] = useState([]);

    useEffect(() => {
        setRandomActions(selectRandomActions(actionsDict, 6));
    }, [actionsDict]);

    const filteredCategories = Object.entries(categories)
        .filter(([categoryName, actions]) => categoryName !== 'All Actions')
        .reduce(_convertObject, {});

    return (
        <Layout navBarType={navBarType}>
            <SEO title="Actions" />
            <section className="act section">
                <div className="container pageHero pageHeroContent">
                    <div className="textBlock">
                        <Title>Actions</Title>
                        <SubTitle>
                            Discover simple actions to make an impact on climate change.
                        </SubTitle>
                        <div className="actGrids">
                            <div className="actGrid">
                                <div className="actGridText">
                                    <h3 className="actGridHeading">Browse by action</h3>
                                    <Link to={encodeCategoryURL('All Actions')}>See All</Link>
                                </div>
                                <ActionsGrid actions={randomActions} />
                            </div>
                            <div className="actGrid">
                                <div className="actGridText">
                                    <h3 className="actGridHeading">Browse by category</h3>
                                </div>
                                <CategoryList categories={filteredCategories} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ActPageTemplate;
