import React from 'react';
import { Link } from 'gatsby';

import { encodeCategoryURL } from '../../utils/link-utils.js';
import { getCategoryImageURL } from '../../utils/category-utils.js';

import '../../styles/global.scss';
import '../../styles/categories.scss';

const CategoryList = ({ categories }) => {
    return (
        <div className="categoryList">
            <div className="categoryGrid">
                {Object.entries(categories).map(([categoryName, actionsInCategory]) => (
                    <div className="category" key={categoryName}>
                        <Link to={encodeCategoryURL(categoryName)}>
                            <img
                                src={getCategoryImageURL(categoryName)}
                                alt={categoryName}
                                className="categoryImg"
                            />
                            <div className="categoryText">
                                <h3 className="categoryName">{categoryName}</h3>

                                <p className="categoryActions">
                                    {actionsInCategory.length} Actions
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryList;
